export enum StatusCode {
  OKAY = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
  INTERNAL_ERROR = 500,
}

export const APIEndpoint = {
  login: 'https://bnnoa1wy0j.execute-api.ap-south-1.amazonaws.com/SecDocsDev',
  logout: 'https://0g6box8zv8.execute-api.ap-south-1.amazonaws.com/SecDocsDev',
  verifyMFA:
    'https://usv6qolp94.execute-api.ap-south-1.amazonaws.com/SecDocsDev',
  changePassword:
    'https://uo1ab2vg9a.execute-api.ap-south-1.amazonaws.com/SecDocsDev',
  forgotPassword:
    'https://lvqbws6hde.execute-api.ap-south-1.amazonaws.com/secDocsDev',
  confirmForgotPassword:
    'https://80c6hpq07e.execute-api.ap-south-1.amazonaws.com/SecDocsDev',
  inviteAdminUser:
    'https://ynpvgfgp04.execute-api.ap-south-1.amazonaws.com/secdocs-dev',
  inviteStaffUser:
    'https://ynpvgfgp04.execute-api.ap-south-1.amazonaws.com/secdocs-dev',
  inviteIndividualCustomer:
    'https://ynpvgfgp04.execute-api.ap-south-1.amazonaws.com/secdocs-dev',
  inviteNewCorporate:
    'https://ynpvgfgp04.execute-api.ap-south-1.amazonaws.com/secdocs-dev',
  inviteCorporateStaffUser:
    'https://ynpvgfgp04.execute-api.ap-south-1.amazonaws.com/secdocs-dev',
  getUsers:
    'https://ycq63ughwi.execute-api.ap-south-1.amazonaws.com/SecDocsDev',
  uploadFile:
    'https://c2xx3zgnw3.execute-api.ap-south-1.amazonaws.com/SecDocsDev',
  getUserDirectory:
    'https://yiemur77rk.execute-api.ap-south-1.amazonaws.com/secdocs-dev',
  validateMFAOTP:
    'https://zp7dazbgv6.execute-api.ap-south-1.amazonaws.com/SecDocsDev',
  createFolder:
    'https://ll6bqne547.execute-api.ap-south-1.amazonaws.com/secdocs-dev',
  getLogo: 'https://zeezq3u9o4.execute-api.ap-south-1.amazonaws.com/SecDocsDev',
  downloadFile:
    'https://lmv3td7887.execute-api.ap-south-1.amazonaws.com/SecDocsDev',
  forgotUsername:
    'https://ig13mk58o2.execute-api.ap-south-1.amazonaws.com/SecDocsDev',
  getFileVersions:
    'https://d9sy5atlqg.execute-api.ap-south-1.amazonaws.com/SecDocsDev',
  updateUserDetails:
    'https://mf967y2jd7.execute-api.ap-south-1.amazonaws.com/SecDocsDev',
  getUserDetails:
    'https://pj6atx834c.execute-api.ap-south-1.amazonaws.com/SecDocsDev',
};
