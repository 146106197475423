import { userTypes } from 'config/config';
import { Route, Routes } from 'react-router-dom';
import AdminDashboard from 'screens/admin/AdminDashboard';
import PreferenceStaff from 'screens/staff/PreferenceStaff';

import Layout from '../components/Layout';
import ProtectedRoute from './ProtectedRoute';

const StaffRoutes = () => (
  <ProtectedRoute allowedRoles={[userTypes.staff]}>
    <Layout>
      <Routes>
        <Route path="dashboard/*" element={<AdminDashboard />} />{' '}
        <Route path="preference" element={<PreferenceStaff />} />
        {/* Assuming a StaffDashboard component */}
        {/* Example staff-specific route */}
      </Routes>
    </Layout>
  </ProtectedRoute>
);
export default StaffRoutes;
