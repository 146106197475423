import { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { APIEndpoint } from 'common/enum';
import { addressFormInterface } from 'common/interface';
import { universalGetRequest } from 'common/requestHandler';
import { popupNotification } from 'notifications/ToastNotification';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import AddressComp from '../../components/address/AddressUpdateComp';
import HeaderComp from '../../components/elements/HeadingComponent';
import Loader from '../../components/elements/Loader';

const PreferenceIndividual = () => {
  const { companyId, username } = useSelector((state: RootState) => state.auth);
  const [userDefaultAddress, setUserDefaultAddress] = useState(
    {} as addressFormInterface
  );
  const [loading, setLoading] = useState(false);
  const getCompanyDetails = async () => {
    setLoading(true);
    const response: any = await universalGetRequest(
      APIEndpoint.getUserDetails,
      {
        actionName: 'user',
        username: username,
        companyId: companyId,
      }
    );
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      if (response.data.address) {
        const address = response?.data?.address;
        setUserDefaultAddress(JSON.parse(address));
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    getCompanyDetails();
  }, []);
  return (
    <>
      <HeaderComp>Personal details</HeaderComp>
      {loading ? (
        <Loader />
      ) : (
        <Grid container justifyContent="center">
          <Grid item>
            <AddressComp addressDefaultValues={userDefaultAddress} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PreferenceIndividual;
