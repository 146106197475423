import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { APIEndpoint } from 'common/enum';
import { universalPostRequest } from 'common/requestHandler';
import { popupNotification } from 'notifications/ToastNotification';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/store';

import CustomModal from '../../components/CustomModal';
import GradientButton from '../../components/elements/GradientButton';
import InputElement from '../../components/elements/InputElement';
import { logout } from '../../redux/slices/authSlice';
import { passwordSchema } from './utils';

export interface ChangePassword {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

const ResetPassword = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { username } = useSelector((state: RootState) => state.auth);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    password: false,
    confirmPassword: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangePassword>({
    resolver: yupResolver(passwordSchema),
    mode: 'onChange',
  });

  const handleTogglePasswordVisibility = (field: keyof ChangePassword) => {
    setShowPassword((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const onSubmit: SubmitHandler<ChangePassword> = async (
    data: ChangePassword
  ) => {
    setLoading(true);
    const response = await universalPostRequest(APIEndpoint.changePassword, {
      username: username,
      previousPassword: data.currentPassword,
      proposedPassword: data.password,
    });
    setLoading(false);
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      setShowModal(true);
      setResponseMessage(response.message);
    }
  };

  return (
    <React.Fragment>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 5,
          height: '100vh',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
            p: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 5,
          }}
        >
          <Typography variant="h5" component="h1">
            Reset your password
          </Typography>
          <Box gap={4} mt={5} width="100%">
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <InputElement
                name="currentPassword"
                control={control}
                label="Current password"
                type={showPassword.currentPassword ? 'text' : 'password'}
                rules={{ required: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          handleTogglePasswordVisibility('currentPassword')
                        }
                        edge="end"
                      >
                        {showPassword.currentPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <InputElement
                name="password"
                control={control}
                label="New password"
                type={showPassword.password ? 'text' : 'password'}
                rules={{ required: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          handleTogglePasswordVisibility('password')
                        }
                        edge="end"
                      >
                        {showPassword.password ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <InputElement
                name="confirmPassword"
                control={control}
                label="Confirm password"
                type={showPassword.confirmPassword ? 'text' : 'password'}
                rules={{ required: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          handleTogglePasswordVisibility('confirmPassword')
                        }
                        edge="end"
                      >
                        {showPassword.confirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <GradientButton type="submit" rounded loading={loading}>
                  Verify
                </GradientButton>
              </Box>
            </form>
          </Box>
        </Box>
        <CustomModal
          title={responseMessage}
          type={responseMessage.length ? 'success' : 'error'}
          open={showModal}
          primaryButtonLabel={responseMessage.length ? 'Login' : 'Try again'}
          primaryButtonAction={() => {
            dispatch(logout());
            navigate('/login');
          }}
          onClose={() => {
            setShowModal(false);
            setResponseMessage('');
          }}
        >
          <Typography variant="body2">{responseMessage}</Typography>
        </CustomModal>
      </Container>
    </React.Fragment>
  );
};

export default ResetPassword;
