import React from 'react';

import CloseIcon from '@mui/icons-material/Close'; // For the close button icon
import {
  Box,
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import { green, orange, red } from '@mui/material/colors';

import GradientButton from './elements/GradientButton';

// Define the types for the modal component
type ModalType = 'success' | 'warning' | 'info' | 'error';

// Define the props for the modal component
interface CustomModalProps {
  open: boolean;
  onClose: () => void;
  type: ModalType;
  title?: string;
  primaryButtonLabel: string;
  primaryButtonAction: () => void;
  secondaryButtonLabel?: string;
  secondaryButtonAction?: () => void;
  primaryButtonLoading?: boolean;
  maxWidth?: false | Breakpoint | undefined;
}

// Define colors based on the type prop
const typeStyles: Record<
  ModalType,
  { background: string; borderColor: string }
> = {
  success: {
    background: green[400], // Green
    borderColor: green[400],
  },
  warning: {
    background: orange[400], // Orange
    borderColor: orange[400],
  },
  info: {
    background: '#2196F3', // Blue
    borderColor: '#2196F3',
  },
  error: {
    background: red[400], // Red
    borderColor: red[400],
  },
};

const CustomModal: React.FC<React.PropsWithChildren<CustomModalProps>> = ({
  open,
  onClose,
  type,
  title,
  children,
  primaryButtonLabel,
  primaryButtonAction,
  secondaryButtonLabel,
  secondaryButtonAction,
  primaryButtonLoading = false,
  maxWidth = 'sm',
}) => {
  const { background, borderColor } = typeStyles[type];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '10px', // Rounded corners for the entire dialog
          border: `1px solid ${borderColor}`, // Border around the entire modal
          //   boxShadow: `0 0 5px 2px ${borderColor}`, // Optional shining border effect
        },
      }}
    >
      {/* Header with Title and Close Button */}
      {title && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between', // Align close button to the right
            alignItems: 'center',
            backgroundColor: background,
            // padding: '5px',
            paddingLeft: '12px',
            color: 'white',
            borderRadius: '8px 8px 0 0', // Rounded top corners
          }}
        >
          <Typography variant="h6" fontWeight="bold" sx={{ textAlign: 'left' }}>
            {title}
          </Typography>

          {/* Close Button */}
          <IconButton onClick={onClose} sx={{ color: 'white' }}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}

      {/* Modal Content */}
      <DialogContent
        sx={{
          padding: '5px',
          paddingLeft: '12px', // Ensure padding for content
          marginTop: 1,
        }}
      >
        {children}
      </DialogContent>

      {/* Modal Actions (Primary & Secondary Buttons) */}
      <DialogActions sx={{ padding: '2rem', gap: 4 }}>
        <GradientButton
          rounded
          variant="contained"
          loading={primaryButtonLoading}
          onClick={() => {
            primaryButtonAction();
            !primaryButtonLoading && onClose();
          }}
          sx={{ marginLeft: 'auto' }}
        >
          {primaryButtonLabel}
        </GradientButton>
        {secondaryButtonLabel && secondaryButtonAction && (
          <Button
            sx={{ borderRadius: 50 }}
            variant="outlined"
            onClick={() => {
              secondaryButtonAction();
              onClose();
            }}
          >
            {secondaryButtonLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CustomModal;
