import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { APIEndpoint } from 'common/enum';
import { universalPostRequest } from 'common/requestHandler';
import { popupNotification } from 'notifications/ToastNotification';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CustomModal from '../../../components/CustomModal';
import GradientButton from '../../../components/elements/GradientButton';
import InputElement from '../../../components/elements/InputElement';
import { logout } from '../../../redux/slices/authSlice';
import { setForgotPasswordSchema } from '../utils';

export interface ChangePassword {
  otp: string;
  username: string;
  password: string;
}

const SetForgotPassword = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangePassword>({
    resolver: yupResolver(setForgotPasswordSchema),
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<ChangePassword> = async (
    data: ChangePassword
  ) => {
    setLoading(true);
    const response = await universalPostRequest(
      APIEndpoint.confirmForgotPassword,
      {
        username: data.username,
        password: data.password,
        otp: data.otp,
      }
    );
    setLoading(false);
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      setShowModal(true);
      setResponseMessage(response.message);
    }
  };

  return (
    <React.Fragment>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 5,
          height: '100vh',
          flexDirection: 'column',
        }}
      >
        <Box
          minWidth="50%"
          sx={{
            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
            p: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 5,
          }}
        >
          <Typography variant="h5" component="h1">
            Set your password
          </Typography>
          <Box gap={4} mt={5} width="100%">
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <InputElement
                name="otp"
                control={control}
                label="OTP"
                type={'text'}
                rules={{ required: true }}
              />
              <InputElement
                name="username"
                control={control}
                label="Username"
                type="text"
                rules={{ required: true }}
              />
              <InputElement
                name="confirmPassword"
                control={control}
                label="Confirm password"
                type={showPassword ? 'text' : 'password'}
                rules={{ required: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword((prev) => !prev)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box display="flex" justifyContent="space-between" mt={2}>
                <Button variant="text" onClick={() => navigate('/login')}>
                  Go back
                </Button>
                <GradientButton type="submit" rounded loading={loading}>
                  Verify
                </GradientButton>
              </Box>
            </form>
          </Box>
        </Box>
        <CustomModal
          title={responseMessage}
          type={responseMessage.length ? 'success' : 'error'}
          open={showModal}
          primaryButtonLabel={responseMessage.length ? 'Login' : 'Try again'}
          primaryButtonAction={() => {
            dispatch(logout());
            navigate('/login');
          }}
          onClose={() => {
            setShowModal(false);
            setResponseMessage('');
          }}
        >
          <Typography variant="body2">{responseMessage}</Typography>
        </CustomModal>
      </Container>
    </React.Fragment>
  );
};

export default SetForgotPassword;
