import React, { useEffect, useState } from 'react';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import FolderIcon from '@mui/icons-material/Folder';
import {
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { APIEndpoint } from 'common/enum';
import { universalGetRequest } from 'common/requestHandler';
import { useNavigate, useParams } from 'react-router-dom';
import colors from 'theme/colors';

import Loader from '../elements/Loader';
import FileItem from './FileItem'; // Ensure this path is correct

// Define the FileData interface
export interface FileData {
  id: string;
  type: 'file' | 'folder';
  name: string;
  modified: string;
  size?: string;
  items?: FileData[];
  'uploaded-by'?: string;
  'file-path'?: string;
}
interface Props {
  // path: string[];
  // setPath: React.Dispatch<React.SetStateAction<string[]>>;
  userFiles: FileData[];
  path: string[]; // Accept path as a prop
  setPath: React.Dispatch<React.SetStateAction<string[]>>;
  loading?: boolean;
  getFileVersions: (file: FileData) => void;
}

//test data

const FileTable: React.FC<Props> = ({
  userFiles,
  path,
  setPath,
  loading = false,
  getFileVersions,
}) => {
  const { uuid, '*': dynamicPath } = useParams();
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  // const [path, setPath] = useState<string[]>(
  //   dynamicPath ? dynamicPath.split('/').filter(Boolean) : []
  // );
  const [currentFolder, setCurrentFolder] = useState<FileData | null>(null);
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set()); // To store selected items
  const navigate = useNavigate();

  useEffect(() => {
    if (dynamicPath) {
      const newPath = dynamicPath.split('/').filter(Boolean);
      setPath(newPath);
    } else {
      setPath([]);
    }
  }, [dynamicPath]);

  useEffect(() => {
    const fetchFilesData = async () => {
      let folderData: FileData[] = userFiles;
      for (const folder of path) {
        const folderItem = folderData.find(
          (item) => item.type === 'folder' && item.name === folder
        );
        if (folderItem && folderItem.items) {
          folderData = folderItem.items;
        } else {
          folderData = [];
        }
      }

      const folder: FileData | null =
        folderData.length > 0
          ? {
              id: 'dummyId',
              type: 'folder',
              name: 'Current Folder',
              modified: 'N/A',
              items: folderData,
            }
          : null;
      setCurrentFolder(folder);
    };

    fetchFilesData();
  }, [path, userFiles]);

  const handleSort = () => {
    if (currentFolder?.items) {
      const sortedData = [...currentFolder.items].sort((a, b) => {
        if (a.name < b.name) return sortDirection === 'asc' ? -1 : 1;
        if (a.name > b.name) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });

      setCurrentFolder((prev) =>
        prev ? { ...prev, items: sortedData } : null
      );
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    }
  };

  const handleFolderClick = (folderName: string) => {
    const newPath = [...path, folderName];
    setPath(newPath);
    navigate(`/${uuid}/dashboard/${newPath.join('/')}`);
  };

  const downloadFile = async (file: FileData) => {
    const response: any = await universalGetRequest(APIEndpoint.downloadFile, {
      fileKey: file['file-path'],
    });
    const presignedUrl = response.data;
    const link = document.createElement('a');
    link.href = presignedUrl;

    link.setAttribute('download', 'file_name.ext');

    document.body.appendChild(link);
    link.click();
    if (link.parentNode) {
      link.parentNode.removeChild(link);
    }
  };

  const handlePathClick = (index: number) => {
    const newPath = path.slice(0, index + 1);
    setPath(newPath);
    navigate(`/${uuid}/dashboard/${newPath.join('/')}`);
  };

  const handleSelect = (id: string, checked: boolean) => {
    setSelectedItems((prev) => {
      const newSet = new Set(prev);
      if (checked) {
        newSet.add(id);
      } else {
        newSet.delete(id);
      }
      return newSet;
    });
  };

  return (
    <div>
      {/* Breadcrumbs: Display path, omit 'dashboard' if at root level */}

      <Typography variant="h6" sx={{ mb: 2, mt: 2 }}>
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            setPath([]);
            navigate(`/${uuid}/dashboard/`);
          }}
          sx={{ textDecoration: 'none', color: colors.primary }}
        >
          Home
        </Link>
        {path.length > 0 && (
          <>
            <Typography variant="body2" component="span" color="gray">
              {' '}
              /{' '}
            </Typography>
            {path.map((segment, index) => (
              <React.Fragment key={segment}>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => handlePathClick(index)}
                  sx={{ textDecoration: 'none', color: colors.primary }}
                >
                  {segment}
                </Link>
                {index < path.length - 1 && (
                  <Typography variant="body2" component="span" color="gray">
                    {' '}
                    /{' '}
                  </Typography>
                )}
              </React.Fragment>
            ))}
          </>
        )}
      </Typography>

      <TableContainer
        component={Paper}
        sx={{ borderRadius: 8, boxShadow: '5px 10px 20px rgba(0, 0, 0, 0.1)' }}
      >
        <Table sx={{ minHeight: '15rem' }}>
          <TableHead
            sx={{
              background: `linear-gradient(90deg, ${colors.gradientColor1} 0%, ${colors.gradientColor2} 100%);`,
            }}
          >
            <TableRow>
              {/* <TableCell>Select</TableCell> */}
              <TableCell sx={{ color: 'white' }}>Download</TableCell>
              <TableCell sx={{ color: 'white' }}>Type</TableCell>
              <TableCell sx={{ color: 'white' }}>
                <Typography
                  variant="body1"
                  component="span"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  Name
                  <IconButton onClick={handleSort} sx={{ ml: 1 }}>
                    {sortDirection === 'asc' ? (
                      <ArrowUpwardIcon sx={{ color: 'white' }} />
                    ) : (
                      <ArrowDownwardIcon sx={{ color: 'white' }} />
                    )}
                  </IconButton>
                </Typography>
              </TableCell>
              <TableCell sx={{ color: 'white' }}>Modified</TableCell>
              <TableCell sx={{ color: 'white' }}>Uploaded by</TableCell>
              <TableCell sx={{ color: 'white' }}>Size</TableCell>
              <TableCell sx={{ color: 'white' }}>Versions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentFolder &&
            Array.isArray(currentFolder?.items) &&
            currentFolder.items.length > 0 ? (
              currentFolder?.items?.map((file) => (
                <FileItem
                  key={file.id}
                  id={file.id}
                  type={file.type}
                  file={file}
                  name={file.name}
                  modified={file.modified}
                  size={file.size}
                  uploadedBy={file['uploaded-by']}
                  onSelect={(checked) => handleSelect(file.id, checked)}
                  getFileVersions={() => getFileVersions(file)}
                  onClick={
                    file.type === 'folder'
                      ? () => handleFolderClick(file.name)
                      : undefined
                  }
                  downloadFile={() => {
                    downloadFile(file);
                  }}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  {loading ? (
                    <Loader />
                  ) : (
                    <Typography
                      variant="body2"
                      display="flex"
                      justifyContent="center"
                      alignContent="center"
                      gap={1}
                    >
                      No files or folders here{' '}
                      <FolderIcon sx={{ color: '#ffcc00' }} />
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default FileTable;
