import { userTypes } from './config';

// Define the JSON object for each user role
const adminMenu = [
  {
    label: 'Dashboard',
    path: 'dashboard',
    icon: 'HomeIcon',
    children: [],
  },
  {
    label: 'Invite User',
    path: 'invite-user',
    icon: 'PersonAddAlt1Icon',
    children: [
      {
        label: 'Invite Staff',
        path: 'invite-user/invite-staff',
      },
      {
        label: 'Invite Customer',
        path: 'invite-user/invite-customer',
      },
      {
        label: 'Invite Corporate',
        path: 'invite-user/invite-corporate',
      },
    ],
  },
];

const superAdminMenu = [
  {
    label: 'Dashboard',
    path: 'dashboard',
    icon: 'HomeIcon',
    children: [],
  },
];

const customerMenu = [
  {
    label: 'Dashboard',
    path: 'dashboard',
    icon: 'HomeIcon',
    children: [],
  },
];

const corporateMenu = [
  {
    label: 'Dashboard',
    path: 'dashboard',
    icon: 'HomeIcon',
    children: [],
  },
];

const staffMenu = [
  {
    label: 'Dashboard',
    path: 'dashboard',
    icon: 'HomeIcon',
    children: [],
  },
];

// Function to return the correct menu based on the user role
const getMenuForUser = (user: string | null) => {
  switch (user) {
    case userTypes.admin:
      return adminMenu;
    case userTypes.individual:
      return customerMenu;
    case userTypes.staff:
      return staffMenu;
    case userTypes.superAdmin:
      return superAdminMenu;
    case userTypes.corporate:
      return corporateMenu;
    default:
      return []; // Return an empty array or a default menu
  }
};

export default getMenuForUser; // Export the function only
