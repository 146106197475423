import axios from 'axios';
import { APIEndpoint } from 'common/enum';
import { defaultErrorMessage } from 'config/config';
import { popupNotification } from 'notifications/ToastNotification';
export const handleFileUpload = async (
  uuid: string | undefined,
  folderId: string | null,
  firstName: string | null,
  lastName: string | null,
  uploadedFiles: File[],
  newFolderPath: string,
  setModalOpen: (isOpen: boolean) => void,
  setUploadedFiles: (files: File[]) => void,
  setUploading: (uploading: boolean) => void,
  setProgress: React.Dispatch<
    React.SetStateAction<{
      [key: string]: number;
    }>
  >
) => {
  setUploading(true);

  const uploadPromises = uploadedFiles.map(async (file) => {
    try {
      const { data } = await axios.post(
        APIEndpoint.uploadFile,
        {
          fileName: `${uuid}/${folderId}/Home/${
            newFolderPath.length > 0 ? newFolderPath + '/' : ''
          }${file.name}`,
          uploadedBy: firstName + ' ' + lastName,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const uploadUrl = data.upload_url.url;

      const formData = new FormData();
      if (data.upload_url.fields) {
        Object.entries(data.upload_url.fields).forEach(([k, v]: any) => {
          formData.append(k, v);
        });
      } else {
        console.error('No fields found in the response');
      }

      formData.append('file', file);

      await axios.post(uploadUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const total = progressEvent.total ?? progressEvent.loaded;
          const progressPercentage = Math.round(
            (progressEvent.loaded / total) * 100
          );
          setProgress((prevProgress: { [key: string]: number }) => ({
            ...prevProgress,
            [file.name]: progressPercentage,
          }));
        },
      });
    } catch (error) {
      popupNotification(defaultErrorMessage, false);
    }
  });

  await Promise.all(uploadPromises).then(() => {
    popupNotification('Files uploaded successfully', true);
    setModalOpen(false);
    setUploadedFiles([]);
    setUploading(false);
  });
};
