import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import { APIEndpoint } from 'common/enum';
import { CustomerProfile } from 'common/interface';
import { universalPostRequest } from 'common/requestHandler';
import { popupNotification } from 'notifications/ToastNotification';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import GradientButton from '../../../components/elements/GradientButton';
import InputElement from '../../../components/elements/InputElement';
import { validationInviteCustomer } from './validation';

const InviteStaffUser = () => {
  const [loading, setLoading] = useState(false);
  const { uuid } = useParams();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CustomerProfile>({
    resolver: yupResolver(validationInviteCustomer),
    mode: 'onChange',
    defaultValues: {
      email: '',
      phone_number: '',
      firstName: '',
      lastName: '',
    } as CustomerProfile,
  });
  const submitHandler = async (data: CustomerProfile) => {
    setLoading(true);
    const response = await universalPostRequest(APIEndpoint.inviteStaffUser, {
      email: data.email,
      phone_number: data.phone_number,
      userType: 'Staff',
      firstName: data.firstName,
      lastName: data.lastName,
      companyId: uuid,
    });
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      popupNotification(response.message, true);
      reset({} as CustomerProfile);
    }
    setLoading(false);
  };
  return (
    <>
      <Typography variant="h5" component="h1">
        Invite staff user
      </Typography>
      <Box display="flex" mt={5} justifyContent="center" width="100%">
        <Box
          sx={{ boxShadow: '5px 10px 20px rgba(0, 0, 0, 0.1)' }}
          p={5}
          borderRadius={8}
          width="100%"
        >
          <legend>
            <Typography variant="subtitle2">Staff user details</Typography>
          </legend>
          <form onSubmit={handleSubmit(submitHandler)}>
            <InputElement
              name="firstName"
              control={control}
              label="First name"
              type="text"
              rules={{ required: true }} // Rules can be added here
            />
            <InputElement
              name="lastName"
              control={control}
              label="Last name"
              type="text"
              rules={{ required: true }} // Rules can be added here
            />

            <InputElement
              name="email"
              control={control}
              label="Email"
              type="text"
              rules={{ required: true }} // Rules can be added here
            />
            <InputElement
              name="phone_number"
              control={control}
              label="Phone"
              type="text"
              rules={{ required: true }} // Rules can be added here
            />
            <Box textAlign="right" mt={4}>
              <GradientButton type="submit" rounded loading={loading}>
                Invite staff user
              </GradientButton>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default InviteStaffUser;
