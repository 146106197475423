import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { APIEndpoint } from 'common/enum';
import { previousFileVersionInterface } from 'common/interface';
import { universalGetRequest } from 'common/requestHandler';
import {
  FileData,
  getUserFilesAndFolders,
  getUsers,
  parseFilesAndFolders,
} from 'common/utils';
import { popupNotification } from 'notifications/ToastNotification';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'redux/store';
import { createFolder } from 'screens/dashboard/utils';
import * as yup from 'yup';

import CustomModal from '../../components/CustomModal';
import CustomDropdown from '../../components/elements/CustomDropdown';
import CustomIconButton from '../../components/elements/IconButton';
import FileTable from '../../components/fileComponent/FileList';
import FileUploadModal from '../../components/fileComponent/FileUpload';
import PreviousVersionFileComp from '../../components/fileComponent/PreviousVersionFileComp';
import { useFileUpload } from '../../hooks/useFileUpload'; // Import the hook

// loading states interface
interface loadingStates {
  fetchingFiles: boolean;
  creatingFolder: boolean;
  loadingUsers: boolean;
  fetchingFileVersion: boolean;
}
// Define validation schema using Yup
const schema = yup
  .object({
    option: yup.string().required('This field is required'),
  })
  .required();

interface FormValues {
  option: string;
}

const AdminDashboard = () => {
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {};
  const { uuid, '*': dynamicPath } = useParams();
  const [users, setUsers] = useState([] as any[]);
  const [loading, setLoading] = useState({} as loadingStates);
  const [userFiles, setUserFiles] = useState([] as FileData[]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState<string>('');
  const [selectedType, setSelectedType] = useState<string>('');
  const [showFileVersionModal, setShowFileVersionModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [previousFileVersions, setPreviousFileVersions] = useState(
    [] as previousFileVersionInterface[]
  );
  const [selectedFileForVersion, setSelectFileForVersion] =
    useState<FileData>();
  const [path, setPath] = useState<string[]>(
    dynamicPath ? dynamicPath.split('/').filter(Boolean) : []
  );
  const { firstName, lastName } = useSelector((state: RootState) => state.auth);

  const selectedOption = watch('option');
  const newFolderPath = path.join('/');

  const { uploadFiles, progress, isUploading, setSelectedFiles, files } =
    useFileUpload();
  const getUsersByAction = async (actionName: string) => {
    setLoading((prev) => ({ ...prev, loadingUsers: true }));
    const response = await getUsers(uuid, actionName);
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      setUsers(response.data);
    }
    setLoading((prev) => ({ ...prev, loadingUsers: false }));
  };

  const getUserFiles = async (selectedUserId: string) => {
    setLoading((prev) => ({ ...prev, fetchingFiles: true }));

    const response = await getUserFilesAndFolders(uuid, selectedUserId);
    if ('error' in response) {
      popupNotification('Something went wrong', false);
    } else {
      const parsedData: FileData[] = parseFilesAndFolders(response.data);
      setUserFiles(parsedData);
    }

    setLoading((prev) => ({ ...prev, fetchingFiles: false }));
  };

  const handleUpload = async () => {
    await uploadFiles(
      uuid,
      selectedOption,
      firstName,
      lastName,
      newFolderPath,
      setModalOpen
    );
    getUserFiles(selectedOption);
  };

  const getFileVersions = async (file: FileData) => {
    setSelectFileForVersion(file);
    setShowFileVersionModal(true);
    setLoading((prev) => ({ ...prev, fetchingFileVersion: true }));
    const response = await universalGetRequest(APIEndpoint.getFileVersions, {
      objectKey: `${file['file-path']}`,
    });
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      const data: any = response.data;
      setPreviousFileVersions(data);
    }
    setLoading((prev) => ({ ...prev, fetchingFileVersion: false }));
  };

  useEffect(() => {
    if (selectedOption) {
      setUserFiles([] as FileData[]);
      getUserFiles(selectedOption);
    }
  }, [selectedOption]);

  const createNewFolder = async () => {
    setLoading((prev) => ({ ...prev, creatingFolder: true }));

    const response = await createFolder(
      `${uuid}/${selectedOption}/Home/${newFolderPath}`,
      newFolderName
    );
    if ('error' in response) {
      popupNotification(response.error, false);
    } else {
      popupNotification('Successfully created folder', true);
    }
    setNewFolderName('');
    getUserFiles(selectedOption);

    setLoading((prev) => ({ ...prev, fetchingFiles: false }));
  };

  const handleFilesSelected = (files: File[]) => {
    setSelectedFiles(files); // Use the hook to set selected files
  };

  return (
    <>
      <Box width="100%" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
        <Grid container>
          <Grid item lg={12} md={12} sm={12}>
            <FormControl fullWidth>
              <FormLabel id="row-radio-buttons-group-label">
                Which user type would you like to access?
              </FormLabel>

              <RadioGroup
                row
                aria-labelledby="row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="GetIndividual"
                  control={<Radio />}
                  label="Individual"
                  onChange={() => {
                    getUsersByAction('GetIndividual');
                    setSelectedType('individual');
                    setValue('option', '');
                    setUserFiles([]);
                  }}
                />
                <FormControlLabel
                  value="GetCorporate"
                  control={<Radio />}
                  label="Corporate"
                  onChange={() => {
                    getUsersByAction('GetCorporate');
                    setSelectedType('corporate');
                    setValue('option', '');
                    setUserFiles([]);
                  }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item lg={5} md={6} sm={12}>
            {selectedType && (
              <CustomDropdown
                name="option"
                control={control}
                options={users}
                label={
                  selectedType === 'individual'
                    ? 'Select user'
                    : 'Select corporate'
                }
                labelKey="name"
                valueKey="id"
                error={errors.option}
                loading={loading.loadingUsers}
              />
            )}
          </Grid>
        </Grid>
        {selectedOption && (
          <Box display="flex" justifyContent="flex-end" gap={4} mr={2}>
            <CustomIconButton
              tooltipTitle="Create folder"
              buttonType="primary"
              onClick={() => setShowModal(true)}
            >
              <CreateNewFolderIcon />
            </CustomIconButton>
            <CustomIconButton
              tooltipTitle="Upload file"
              buttonType="primary"
              onClick={() => setModalOpen(true)}
            >
              <UploadFileIcon />
            </CustomIconButton>
          </Box>
        )}

        <FileUploadModal
          open={isModalOpen}
          onClose={() => {
            setModalOpen(false);
            setSelectedFiles([]); // Clear files when modal closes
          }}
          onFilesSelected={handleFilesSelected}
          progress={progress}
          onUpload={handleUpload}
          uploading={isUploading} // Use uploading state from the hook
          files={files} // Use files state from the hook
        />
        <FileTable
          userFiles={userFiles}
          path={path}
          setPath={setPath}
          getFileVersions={getFileVersions}
          loading={loading.fetchingFiles}
        />
      </Box>

      <CustomModal
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setNewFolderName('');
        }}
        type="info"
        title="Create new folder"
        primaryButtonLabel="Ok"
        secondaryButtonAction={() => {
          setShowModal(false);
          setNewFolderName('');
        }}
        secondaryButtonLabel="Cancel"
        primaryButtonAction={createNewFolder}
      >
        <TextField
          fullWidth
          label="Folder Name"
          autoFocus
          size="small"
          variant="outlined"
          value={newFolderName}
          onChange={(e) => setNewFolderName(e.target.value)}
        />
      </CustomModal>

      <PreviousVersionFileComp
        folderId={selectedOption}
        setPreviousFileVersions={setPreviousFileVersions}
        showFileVersionModal={showFileVersionModal}
        setShowFileVersionModal={setShowFileVersionModal}
        loading={loading.fetchingFileVersion}
        previousFileVersions={previousFileVersions}
        fileName={selectedFileForVersion?.name || ''}
      />
    </>
  );
};

export default AdminDashboard;
