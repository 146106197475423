import { userTypes } from 'config/config';
import PageNotFound from 'pages/404';
import UnauthorizedPage from 'pages/UnauthorizedPage';
import { Route, Routes } from 'react-router-dom';
import AdminDashboard from 'screens/admin/AdminDashboard';
import Invite from 'screens/admin/invite/Invite';
import InviteCorporate from 'screens/admin/invite/InviteCorporate';
import InviteCustomer from 'screens/admin/invite/InviteCustomer';
import InviteStaffUser from 'screens/admin/invite/InviteStaffuser';
import PreferenceAdmin from 'screens/admin/PreferenceAdmin';

import Layout from '../components/Layout';
import ProtectedRoute from './ProtectedRoute';

const AdminRoutes = () => (
  <ProtectedRoute allowedRoles={[userTypes.admin]}>
    <Layout>
      <Routes>
        <Route path="dashboard/*" element={<AdminDashboard />} />
        <Route path="invite-user" element={<Invite />} />
        <Route
          path="invite-user/invite-customer"
          element={<InviteCustomer />}
        />
        <Route path="invite-user/invite-staff" element={<InviteStaffUser />} />
        <Route
          path="invite-user/invite-corporate"
          element={<InviteCorporate />}
        />
        <Route path="preference" element={<PreferenceAdmin />} />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Layout>
  </ProtectedRoute>
);
export default AdminRoutes;
