import React from 'react';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FolderIcon from '@mui/icons-material/Folder';
import {
  Button,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

import { FileData } from './FileList';
import RenderFileIcon from './RenderFileIcon';

interface FileItemProps {
  id: string;
  type: 'file' | 'folder';
  name: string;
  modified: string;
  size?: string;
  onSelect?: (checked: boolean) => void;
  onClick?: () => void;
  downloadFile?: () => void;
  uploadedBy?: string;
  getFileVersions: (file: FileData) => void;
  file: FileData;
}

const FileItem: React.FC<FileItemProps> = ({
  id,
  type,
  name,
  modified,
  size,
  onSelect,
  onClick,
  downloadFile,
  uploadedBy,
  getFileVersions,
  file,
}) => {
  const theme = useTheme();
  return (
    <TableRow
      sx={{
        '&:hover': {
          backgroundColor: theme.palette.grey[100], // Light version of primary color
          cursor: type === 'folder' ? 'pointer' : 'default',
        },
      }}
    >
      {/* <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          onChange={(e) => onSelect?.(e.target.checked)}
        />
      </TableCell> */}
      <TableCell>
        <Typography variant="body2">
          {type !== 'folder' ? (
            <IconButton onClick={() => downloadFile && downloadFile()}>
              <CloudDownloadIcon color="primary" />
            </IconButton>
          ) : (
            '-'
          )}
        </Typography>
      </TableCell>
      <TableCell>
        {type === 'folder' ? (
          <FolderIcon sx={{ color: '#ffcc00' }} />
        ) : (
          <RenderFileIcon fileType={name.split('.')[1]} />
          // <DescriptionIcon sx={{ color: '#4caf50' }} />
        )}
      </TableCell>
      <TableCell onClick={type === 'folder' ? onClick : undefined}>
        <Typography variant="body1">{name}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{modified}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{uploadedBy ?? '-'}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{size || '-'}</Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body2"
          component={Button}
          onClick={() => getFileVersions(file)}
          sx={{ textDecoration: 'none' }}
        >
          {type === 'folder' ? '-' : 'Get details'}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default FileItem;
