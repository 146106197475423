export const reducerCodes = {
  idToken: '11',
};

export const userTypes = {
  admin: 'Admin',
  individual: 'Individual',
  superAdmin: 'Superadmin',
  corporate: 'Corporate',
  staff: 'Staff',
};
export const defaultErrorMessage = 'Something went wrong, try again later';
