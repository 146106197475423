import React from 'react';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { APIEndpoint } from 'common/enum';
import { previousFileVersionInterface } from 'common/interface';
import { universalGetRequest } from 'common/requestHandler';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import CustomModal from '../CustomModal';
import Loader from '../elements/Loader';

interface Props {
  showFileVersionModal: boolean;
  setShowFileVersionModal: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  previousFileVersions: previousFileVersionInterface[];
  fileName: string;
  setPreviousFileVersions: React.Dispatch<
    React.SetStateAction<previousFileVersionInterface[]>
  >;
  folderId: string | null;
}

const PreviousVersionFileComp: React.FC<Props> = ({
  showFileVersionModal,
  setShowFileVersionModal,
  loading,
  previousFileVersions,
  fileName,
  setPreviousFileVersions,
  folderId,
}) => {
  const { companyId } = useSelector((state: RootState) => state.auth);
  const downloadFile = async (file: previousFileVersionInterface) => {
    const response: any = await universalGetRequest(APIEndpoint.downloadFile, {
      fileKey: `${companyId}/${folderId ? folderId + '/' : ''}${file.filePath}`,
      versionId: file.versionId,
      oldVersion: true,
    });
    const presignedUrl = response.data;
    const link = document.createElement('a');
    link.href = presignedUrl;
    link.setAttribute('download', 'file_name.ext');
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) {
      link.parentNode.removeChild(link);
    }
  };
  return (
    <>
      <CustomModal
        maxWidth="lg"
        type="info"
        open={showFileVersionModal}
        onClose={() => {
          setShowFileVersionModal(false);
          setPreviousFileVersions([]);
        }}
        title="File version"
        primaryButtonAction={() => {
          setShowFileVersionModal(false);
          setPreviousFileVersions([]);
        }}
        primaryButtonLabel="Close"
      >
        {loading ? (
          <Loader />
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Download</TableCell>
                  <TableCell align="center">File name</TableCell>
                  <TableCell align="center">Uploaded by</TableCell>
                  <TableCell align="center">Last modified</TableCell>
                  <TableCell align="center">Version id</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {previousFileVersions.map((file) => (
                  <TableRow>
                    <TableCell align="center">
                      {' '}
                      <IconButton onClick={() => downloadFile(file)}>
                        <CloudDownloadIcon color="primary" />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">{fileName}</TableCell>
                    <TableCell align="center">{file.uploadedBy}</TableCell>
                    <TableCell align="center">{file.lastModified}</TableCell>
                    <TableCell align="center">{file.versionId}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </CustomModal>
    </>
  );
};

export default PreviousVersionFileComp;
