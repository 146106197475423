import { useState } from 'react';

import axios from 'axios';
import { APIEndpoint } from 'common/enum'; // Adjust import path accordingly
import { popupNotification } from 'notifications/ToastNotification'; // Assuming this is the correct notification method
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const defaultErrorMessage = 'File upload failed. Please try again.';

export const useFileUpload = () => {
  const [progress, setProgress] = useState<{ [key: string]: number }>({});
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const { accessToken } = useSelector((state: RootState) => state.auth);

  const uploadFiles = async (
    uuid: string | undefined | null,
    folderId: string | null,
    firstName: string | null,
    lastName: string | null,
    newFolderPath: string,
    setModalOpen: (isOpen: boolean) => void,
    home: string | null = 'Home'
  ) => {
    setIsUploading(true);

    const uploadPromises = files.map(async (file) => {
      try {
        const { data } = await axios.post(
          APIEndpoint.uploadFile,
          {
            fileName: `${uuid}/${folderId ? folderId + '/' : ''}${home ? home + '/' : ''}${
              newFolderPath.length > 0 ? newFolderPath + '/' : ''
            }${file.name}`,
            uploadedBy: `${firstName} ${lastName}`,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + accessToken,
            },
          }
        );

        const uploadUrl = data.upload_url.url;

        const formData = new FormData();
        if (data.upload_url.fields) {
          Object.entries(data.upload_url.fields).forEach(([k, v]: any) => {
            formData.append(k, v);
          });
        } else {
          console.error('No fields found in the response');
        }

        formData.append('file', file);

        await axios.post(uploadUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const total = progressEvent.total ?? progressEvent.loaded;
            const progressPercentage = Math.round(
              (progressEvent.loaded / total) * 100
            );
            setProgress((prevProgress) => ({
              ...prevProgress,
              [file.name]: progressPercentage,
            }));
          },
        });
      } catch (error) {
        console.error('Error uploading file:', error);
        popupNotification(defaultErrorMessage, false);
      }
    });

    await Promise.all(uploadPromises).then(() => {
      popupNotification('Files uploaded successfully', true);
      setModalOpen(false);
      setFiles([]); // Clear files after upload
      setProgress({}); // Reset progress after upload
      setIsUploading(false);
    });
  };

  const setSelectedFiles = (selectedFiles: File[]) => {
    setFiles(selectedFiles);
    setProgress({}); // Reset progress when new files are selected
  };

  return { uploadFiles, progress, isUploading, setSelectedFiles, files };
};
