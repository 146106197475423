import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AppleIcon from '@mui/icons-material/Apple';
import ShopIcon from '@mui/icons-material/Shop';
import {
  Box,
  Button,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Link as MUILink,
  Typography,
} from '@mui/material';
import { APIEndpoint } from 'common/enum';
import { LoginResponse } from 'common/interface';
import { universalPostRequest } from 'common/requestHandler';
import { popupNotification } from 'notifications/ToastNotification';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { validateUsernameOrEmail } from 'screens/superAdmin/validation';
import colors from 'theme/colors';
import * as yup from 'yup';

import docImage from '../../assets/images/login2.svg';
import logo from '../../assets/images/logo.png';
import CustomModal from '../../components/CustomModal';
import GradientButton from '../../components/elements/GradientButton';
import InputElement from '../../components/elements/InputElement'; // Your flexible input component
import useNavigateWithUUID from '../../hooks/useNavigateWithUUID';
import { login } from '../../redux/slices/authSlice';
interface LoginFormInputs {
  username: string;
  password: string;
}

const schema = yup.object().shape({
  username: validateUsernameOrEmail,
  password: yup
    .string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
});

const LoginPage: React.FC = () => {
  const {
    control,
    handleSubmit,

    formState: { errors },
  } = useForm<LoginFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const dispatch = useDispatch();
  const { navigateWithUuid } = useNavigateWithUUID();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const onSubmit: SubmitHandler<LoginFormInputs> = async (data) => {
    setLoading(true);
    const response = await universalPostRequest<LoginResponse>(
      APIEndpoint.login,
      {
        username: data.username,
        password: data.password,
      }
    );
    setLoading(false);
    // Check if the response is an error
    if ('error' in response) {
      // It's an error, so handle the error case here
      console.error('Request failed:', response.error);
      // Handle error message, display to user, etc.
      popupNotification(response.error, false);
    } else {
      // It's a successful login, dispatch the login action
      // Display
      dispatch(
        login({
          accessToken: response.data.access_token,
          refreshToken: response.data.refresh_token,
          idToken: response.data.id_token,
          expiresIn: response.data.expires_in,
          date: response.data.date,
          deviceKey: response.data.device_key,
          secretCode: response.data.secret_code,
          username: response.data.username,
          isFirstLogin: response.data.is_first_login,
          isMfaActive: response.data.is_mfa_active,
          // role: response.data.role,
          sessionKey: response.data.session_key,
        })
      );
      if (response.data.is_first_login) {
        navigate('/mfa-config');
      } else {
        navigate('/verify-mfa');
      }
    }
  };

  return (
    <Grid container style={{ height: '100vh', backgroundColor: '#F9F9F9' }}>
      {/* Left side with form */}
      <Grid
        item
        xs={12}
        md={6}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Box display="flex" justifyContent="center">
          <img src={logo} alt="Logo" height={100} />
        </Box>
        <Box sx={{ width: '80%', padding: '20px' }}>
          <Typography
            variant="h4"
            fontWeight="bold"
            component="h1"
            gutterBottom
            fontFamily="Poppins"
          >
            Sign in
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <InputElement
              name="username"
              control={control}
              required
              label="Username or Email"
              type="text"
              autoFocus
              rules={{ required: true }} // Rules can be added here
            />
            <InputElement
              name="password"
              control={control}
              label="Password"
              type={showPassword ? 'text' : 'password'}
              rules={{ required: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword((prev) => !prev)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box mt={2}>
              <GradientButton fullWidth type="submit" loading={loading}>
                Sign in
              </GradientButton>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              mt={2}
            >
              <Button
                onClick={() => setShowModal(true)}
                style={{ color: colors.primary, textDecoration: 'none' }}
              >
                Need help?
              </Button>
              <Box display="flex" flexDirection="column" gap={1}>
                <Link
                  to="/forgot-username"
                  style={{ color: colors.primary, textDecoration: 'none' }}
                >
                  Forgot username
                </Link>
                <Link
                  to="/forgot-password"
                  style={{ color: colors.primary, textDecoration: 'none' }}
                >
                  Forgot password
                </Link>
              </Box>
            </Box>
            {/* <Box mt={2}>
              <Divider>
                <Typography variant="subtitle2" color="textDisabled">
                  or
                </Typography>
              </Divider>
            </Box>
            <Box mt={1} display="flex" justifyContent="center" gap={2}>
              <img src={googleImage} height={20} alt="Google image" />
              <img src={appleImage} height={20} alt="Apple image" />
              <img src={facebookImage} height={20} alt="Facebook image" />
            </Box> */}
          </form>
        </Box>
      </Grid>
      {/* Right side with image */}
      <Hidden smDown>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'whitesmoke',
          }}
        >
          <Typography
            variant="h6"
            fontWeight="bold"
            align="center"
            mt={10}
            sx={{
              background: 'linear-gradient(45deg, #42d392, #647eff)', // Gradient colors
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontWeight: 'bold',
              //   color: 'white',
              fontSize: '2rem', // Adjust font size as needed
            }}
          >
            Share Documents Securely
          </Typography>
          <Typography
            variant="body1"
            fontWeight="bold"
            textAlign="center"
            color="white"
            sx={{
              background: 'linear-gradient(45deg, #42d392, #647eff)', // Gradient colors
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontWeight: 'bold',
              // fontSize: '2rem', // Adjust font size as needed
            }}
          >
            Seamless sharing with top security
          </Typography>
          <img
            src={docImage}
            alt=""
            //   height="100%"
            //   style={{ objectFit: 'cover' }}
          />
        </Grid>
      </Hidden>
      <CustomModal
        type="info"
        open={showModal}
        onClose={() => setShowModal(false)}
        primaryButtonAction={() => setShowModal(false)}
        primaryButtonLabel="Ok"
        title="Help"
      >
        <Typography variant="subtitle1" color="textDisabled">
          Before logging in for the first time, download a software token
          authenticator on your mobile phone.
        </Typography>
        <ul>
          <li>
            Get the authenticator app from the{' '}
            <MUILink
              href="https://play.google.com/store/search?q=Authenticator&c=apps"
              rel="noreferrer"
              target="_blank"
              sx={{ textDecoration: 'none' }}
            >
              Play Store <ShopIcon />
            </MUILink>{' '}
            or{' '}
            <MUILink
              href="https://apps.apple.com/search?term=Authenticator"
              rel="noreferrer"
              target="_blank"
              sx={{ textDecoration: 'none' }}
            >
              App Store
              <AppleIcon />
            </MUILink>
          </li>
          <li>
            In the app select{' '}
            <span style={{ fontWeight: 600 }}>Set up account</span>
          </li>
          <li>
            Choose <span style={{ fontWeight: 600 }}>Scan barcode</span>
          </li>
        </ul>
      </CustomModal>
    </Grid>
  );
};

export default LoginPage;
// style={{
//   background: `url(${documentSharingImage}) no-repeat center/cover`,
// }}
