import React from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/system';

interface GradientCardProps {
  children: React.ReactNode; // Accept children prop
}

const GradientCard = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(45deg, #42d392, #647eff)', // Linear gradient background
  borderRadius: '12px', // Card border radius
  padding: '.2rem 1rem', // Internal padding
  color: '#fff', // Text color
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', // Soft shadow
  transition: 'transform 0.3s ease-in-out', // Smooth transition for hover effect
  cursor: 'pointer',

  '&:hover': {
    transform: 'translateY(-2px)', // Slight horizontal movement on hover
    boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.2)', // Enhanced shadow on hover
  },
}));

const GradientCardComponent: React.FC<GradientCardProps> = ({ children }) => {
  return <GradientCard>{children}</GradientCard>;
};

export default GradientCardComponent;
