import { userTypes } from 'config/config';
import PageNotFound from 'pages/404';
import { Route, Routes } from 'react-router-dom';
import PreferenceCorporate from 'screens/corporate/PreferenceCorporate';
import DashboardPage from 'screens/dashboard/Dashboard';

import Layout from '../components/Layout';
import ProtectedRoute from './ProtectedRoute';

const CorporateRoutes = () => (
  <ProtectedRoute allowedRoles={[userTypes.corporate]}>
    <Layout>
      <Routes>
        <Route path="dashboard/*" element={<DashboardPage />} />
        <Route path="preference" element={<PreferenceCorporate />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Layout>
  </ProtectedRoute>
);
export default CorporateRoutes;
